import { mapGetters } from 'vuex'

import moment from 'moment-timezone'
import axios from 'axios'

export default {
  data: () => ({
    loading: false,
    dateRange: { startDate: null, endDate: null },
    selectedClasses: [],
    fetch_service_classes: [],
    service_classes: [],
    serviceClassMenu: false,
    filterMenu: false,
  }),

  async mounted () {
    this.$root.$on('updateData', async () => {
      await this.fetchData(this.searchTerm)
    })

    const token = this.$store.state.token
    const url = process.env.VUE_APP_API_URL

    // Service Classes
    let _service_classes = JSON.parse(localStorage.getItem('service_classes'))

    if (!_service_classes) {
      const { data: service_classes } = await axios.get( `${url}/api/service-classes`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      localStorage.setItem('service_classes', JSON.stringify(service_classes))
      _service_classes = service_classes
    }

    this.service_classes = _service_classes.map( s => {
      return { value: s.id, text: s.name }
    })

    this.service_classes.push({ value: 'sbr', text: 'Scan-Based Return' })

    this.selectedClasses = this.service_classes.map( s => s.value )
    this.fetch_service_classes = _service_classes
  },

  computed: {
    ...mapGetters([ 'getTz' ]),

    getMaxDate() {
      if(this.getTz()) return moment.tz(this.getTz()).format('lll')
      return moment.utc().format('lll')
    },

    selectedAllClasses() {
      return this.selectedClasses.length === this.service_classes.length
    },

    selectedSomeClasses() {
      return this.selectedClasses.length > 0 && !this.selectedAllClasses
    },

    icon() {
      if (this.selectedAllClasses) return 'mdi-close-box'
      if (this.selectedSomeClasses) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },

    ranges() {
      const today = moment()
      const week = today.clone()
      const month = today.clone()
      const last_month = today.clone()
      const year = today.clone()

      return {
        'Today': [today.toDate(), today.toDate()],
        'This week': [week.startOf('week').toDate(), week.endOf('week').toDate()],
        'This month': [month.startOf('month').toDate(), month.endOf('month').toDate()],
        'Last month': [last_month.subtract(1,'months').startOf('month').toDate(), last_month.endOf('month').toDate()],
        'This Year': [year.startOf('year').toDate(), year.endOf('year').toDate()],
      }
    },
  },

  methods: {
    update() {
      this.filterMenu = false
      this.fetchData(this.searchTerm)
    },

    toggleServiceClass () {
      this.$nextTick(() => {
        if (this.selectedAllClasses) {
          this.selectedClasses = []
        } else {
          this.selectedClasses = this.service_classes.map(sc => sc.value)
        }
      })
    },

    toggleDateRange (isToggled) {
      if (isToggled && this.$vuetify.theme.dark) {
        this.$nextTick( () => {
          let dateRangeContainer = document.getElementsByClassName('daterangepicker')[0]
          if (dateRangeContainer) {
            dateRangeContainer.classList.add('theme--dark')
          }
        })
      }
    },
  }
}